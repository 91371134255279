import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import LicensePage from '../ui/pages/LicensePage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/de.json'

const License = props => (
  <>
    <HelmetComponent
      title={metaTags.licenseTitle}
      description={metaTags.pricing}
      page="de/license"
    />
    <AppWrapper {...props} lang="de">
      <LicensePage />
    </AppWrapper>
  </>
)

export default License
